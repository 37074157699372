import localforage from 'localforage';
import { PASSES, SUBSCRIPTION } from 'state/store';

export const canAddActivePasses = async () => {
  const subscription = await localforage.getItem(SUBSCRIPTION);
  if (subscription && 'model' in subscription) {
    const { model = {} } = subscription;
    const { maxPassType = 1 } = model;
    const passes = await localforage.getItem(PASSES);
    const activePasses = passes.filter((p) => p.state === 'active');
    return Array.isArray(activePasses) && activePasses.length < maxPassType;
  }
  return false;
};
