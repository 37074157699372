import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';

import queryString from 'query-string';
import { SkeletonHomeAdvertisingView, SkeletonHomeView } from 'views/home/skeleton';
import SkeletonCreateInformationView from 'views/loyalty-card/create/skeleton';
import { useNavigationContext } from 'contexts/navigation-context';

import { isAuthenticated } from 'services/authentication-service';
import { canAddActivePasses } from 'state/selectors/can-add-active-passes';

const LazyCreateLoyaltyCard = loadable(() => import('suspended-page/loyalty-card/create-media'));

const CreateLoyaltyCardMediaPage = ({ location = {} }) => {
  const [loading, setLoading] = useState(true);
  const [cannotAdd, setCanAdd] = useState(false);
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const {
    homePageOnly = () => {},
    noPassToEdit = () => {},
  } = useNavigationContext();

  const authenticated = isAuthenticated();

  useEffect(() => {
    const setCanAdddAsync = async () => {
      const can = await canAddActivePasses();
      setCanAdd(!can);
    };

    if (!passId) {
      noPassToEdit();
    } else {
      setLoading(false);
    }
    if (authenticated) {
      setCanAdddAsync();
    }

    // eslint-disable-next-line
  }, []);

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (cannotAdd) {
    homePageOnly();
    return (<SkeletonHomeView />);
  }

  if (loading) {
    return (<SkeletonCreateInformationView />);
  }

  return (<LazyCreateLoyaltyCard passId={passId}/>);
};

CreateLoyaltyCardMediaPage.propTypes = { location: object };

export default CreateLoyaltyCardMediaPage;
